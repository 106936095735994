exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bocker-js": () => import("./../../../src/pages/bocker.js" /* webpackChunkName: "component---src-pages-bocker-js" */),
  "component---src-pages-forfattare-js": () => import("./../../../src/pages/forfattare.js" /* webpackChunkName: "component---src-pages-forfattare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-template-tsx": () => import("./../../../src/templates/authorTemplate.tsx" /* webpackChunkName: "component---src-templates-author-template-tsx" */),
  "component---src-templates-blog-post-archive-template-tsx": () => import("./../../../src/templates/blogPostArchiveTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-archive-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-book-template-tsx": () => import("./../../../src/templates/bookTemplate.tsx" /* webpackChunkName: "component---src-templates-book-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

